<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import CustomerShow from "./show/show.vue";
import CustomerForm from "./forms/customer-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    CustomerShow,
    CustomTable,
    CustomerForm
  },
  data() {
    return {
      showModalId:'customer-show-modal',
      formModalId:'customer-form-modal',
      pageHeader:{
        title: "Clientes",
        breadcrumbs: [
          {
            text: "Clientes",
            href: "/customers",
            active: true
          }
        ],
      },
      table:{
        url:'/customers',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "identification_type.prefix", label: "Tipo de identificación", sortable: false },
          { key: "identification", label: "Identificación", sortable: false },
          { key: "user.email", label: "Email", sortable: false },
          { key: "created_at", label: "Fecha de creación", sortable: true },
        ],
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    showFormModal(item) {
      this.formSelectedItemId = item?.id || null;
      this.$bvModal.show(this.formModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <CustomerShow :modal-id="showModalId" :id="showSelectedItemId"></CustomerShow>
            <CustomerForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></CustomerForm>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :params="table.params"
              ref="customerTable"
              :show-view="true"
              :show-edit="true"
              :showSoftDelete="false"
              @show="showViewModal"
              @edit="showFormModal"
              :show-modal-id="showModalId"
            >
              <!-- <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="customer_type">Tipo de cliente</label>
                        <input id="customer_type" v-model="filters.customer_type" type="text" class="form-control" placeholder="Ingresa un texto"/>
                    </div>
                </div> 
              </template> -->
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>